import { Client } from "api/axios";
import { useParams } from "react-router-dom";
import {
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import TemplateDetails from "./TemplateDetails";

function TemplateEdit() {
  const { code } = useParams();
  const queryClient = useQueryClient();
  async function fetchData() {
    return await Client(true).get(`/api/templates/${code}`);
  }

  const { data: templateData } = useQuery({
    queryKey: [`/api/templates/${code}`],
    queryFn: fetchData,
    keepPreviousData: true,
    initialData: {},
  });

  return (
    <QueryClientProvider client={queryClient}>
      {templateData?.data && templateData?.data?.code && (
        <TemplateDetails
          templateData={templateData?.data}
          onVersionUpdate={() => {
            queryClient.invalidateQueries({
              queryKey: [`/api/templates/${code}`],
            });
          }}
        />
      )}
    </QueryClientProvider>
  );
}

export default TemplateEdit;
