import {
  Button,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  useReactTable,
  getExpandedRowModel,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import { Client } from "api/axios";
import Card from "components/card/Card";
import { Fragment, useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";

export default function TemplatesTable({ tableData }) {
  const [data, setData] = useState(() => tableData || []);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columnHelper = createColumnHelper();

  const sendTemplatePreviewFn = async (code) => {
    const client = Client(true);
    await client.post(`/api/templates/${code}/preview`);
  };

  const sendTemplatePreview = useMutation({
    mutationFn: sendTemplatePreviewFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
    },
  });

  const sendPreview = (code) => () => {
    sendTemplatePreview.mutate(code);
  };
  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: ({ row }) => {
        return (
          <VStack align="baseline" gap={0.5}>
            <Link to={`/admin/settings/templates/${row.original.code}`}>
              {row.original.name}
            </Link>
          </VStack>
        );
      },
    }),
    columnHelper.accessor("code", {
      header: "Code",
    }),
    columnHelper.accessor("version", {
      header: "Version",
    }),

    columnHelper.accessor("id", {
      header: "Actions",
      cell: ({ row }) => {
        return (
          <VStack align="baseline" gap={1}>
            <Button
              rightIcon={<MdEmail />}
              size={"xs"}
              onClick={sendPreview(row.original.code)}
            >
              Preview
            </Button>
          </VStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table size="sm" fontSize="xs">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={"fragment-" + row.id}>
              <Tr key={"row-" + row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            </Fragment>
          ))}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
    </Card>
  );
}
