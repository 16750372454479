import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";
import BasicMetricView from "views/admin/payouts/components/ExpandableRows/BasicMetricView";
import TemplateHtmlEditor from "./TemplateHtmlEditor";
import { Fragment } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MdEmail } from "react-icons/md";

export default function TemplateDetails({
  templateData: data,
  onVersionUpdate,
}) {
  const queryClient = useQueryClient();
  const newVersionModal = useDisclosure();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
      subject: data?.subject,
      intro: data?.intro,
      active: true,
    },
  });

  async function fetchVersions() {
    return await Client(true).get(`/api/templates/${data.code}/versions`);
  }

  const { data: versions } = useQuery({
    queryKey: [`/api/templates/${data.code}/versions`],
    queryFn: fetchVersions,
    keepPreviousData: true,
    initialData: {},
  });

  const columnHelper = createColumnHelper();

  const sendTemplatePreviewFn = async ({ code, version }) => {
    const client = Client(true);
    await client.post(`/api/templates/${code}/preview?version=${version}`);
  };

  const sendTemplatePreview = useMutation({
    mutationFn: sendTemplatePreviewFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
    },
  });

  const sendPreview = (code, version) => () => {
    sendTemplatePreview.mutate({ code, version });
  };
  const columns = [
    columnHelper.accessor("version", {
      header: "Version",
    }),
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("active", {
      header: "Active",
    }),

    columnHelper.accessor("id", {
      header: "Actions",
      cell: ({ row }) => {
        return (
          <VStack align="baseline" gap={1}>
            <Button
              size={"xs"}
              rightIcon={<MdEmail />}
              onClick={sendPreview(row.original.code, row.original.version)}
            >
              Preview (v{row.original.version})
            </Button>
          </VStack>
        );
      },
    }),
  ];

  // console.log("versions", versions);
  const table = useReactTable({
    data: versions?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onSubmit = (values) => {
    createTemplateVersion.mutate(values);
    newVersionModal.onClose();
    reset();
  };

  const createTemplateVersionFn = async (values) => {
    const payload = {
      name: values.name,
      subject: values.subject,
      intro: values.intro,
      active: values.active,
    };
    const client = Client(true);
    await client.post(`/api/templates/${data.code}`, payload);
  };

  const createTemplateVersion = useMutation({
    mutationFn: createTemplateVersionFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
      await queryClient.invalidateQueries({
        queryKey: [`/api/templates/${data.code}/versions`],
      });
      onVersionUpdate && onVersionUpdate();
    },
  });
  if (!data) {
    return "No data";
  }
  return (
    <>
      <Box>
        <Box title="Template Details">
          <Text fontSize={"x-large"}>Template Details</Text>
        </Box>
        <GroupingContainer title="Template details">
          <VStack gap={2} align={"flex-start"}>
            <BasicMetricView
              style={{ width: "100%" }}
              textStyle={{ minWidth: "150px", width: "auto" }}
              data={[
                {
                  label: "Code",
                  value: data.code,
                },
                {
                  label: "Current Version",
                  value: data?.version,
                },
                {
                  label: "Latest Version",
                  value: data?.version,
                },
              ]}
            />

            {/* <Button onClick={newVersionModal.onOpen}>Create new version</Button> */}
          </VStack>
        </GroupingContainer>

        <Table mt={5} size="sm" fontSize="xs">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={"fragment-" + row.id}>
                <Tr key={"row-" + row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              </Fragment>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </Box>
      <Modal
        size={"5xl"}
        isOpen={newVersionModal.isOpen}
        onClose={newVersionModal.onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack gap={2}>
              <FormControl isInvalid={errors.name} variant="floating">
                <InputGroup>
                  <Input {...register("name", {})} />
                </InputGroup>
                <FormLabel>Template name</FormLabel>
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.subject} variant="floating">
                <InputGroup>
                  <Input {...register("subject", {})} />
                </InputGroup>
                <FormLabel>Email Subject</FormLabel>
                <FormErrorMessage>
                  {errors.subject && errors.subject.message}
                </FormErrorMessage>
              </FormControl>

              <TemplateHtmlEditor
                value={data.intro}
                onEditorChange={(d) => setValue("intro", d)}
              />

              <FormControl>
                <CheckboxGroup>
                  <Checkbox {...register("active", {})}>Active</Checkbox>
                </CheckboxGroup>
              </FormControl>
              <Button type="submit">Create New Version</Button>
            </VStack>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
