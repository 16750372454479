import React, { useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import { TOGGLE_LINK_COMMAND, $isLinkNode } from "@lexical/link";
import { MdLink } from "react-icons/md";
import { Button, FormControl, Input } from "@chakra-ui/react";

function LinkEditorButton() {
  const [editor] = useLexicalComposerContext();
  const [showPopup, setShowPopup] = useState(false);
  const [url, setUrl] = useState("");

  const handleButtonClick = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    editor.update(() => {
      try {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();
          const parent = anchorNode.getParent();
          const linkNode = $isLinkNode(anchorNode)
            ? anchorNode
            : $isLinkNode(parent)
              ? parent
              : null;

          if (linkNode) {
            setUrl(linkNode.getURL());
          } else {
            setUrl("");
          }
        }
      } catch (e) {
        console.log("error", e);
      }
    });

    setShowPopup(true);
  };

  const handleSave = () => {
    editor.update(() => {
      if (url) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        setShowPopup(false);
      }
    });
  };

  return (
    <>
      <button type="button" onClick={handleButtonClick}>
        <MdLink />
      </button>
      {showPopup && (
        <div className="popup">
          <div
            className="popup-overlay"
            onClick={() => {
              setShowPopup(false);
            }}
          ></div>
          <div className="popup-content">
            <FormControl>
              <Input
                color={"white"}
                placeholder="URL"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </FormControl>

            <Button
              colorScheme="blue"
              size="xs"
              variant=""
              className={"toolbar-item spaced "}
              onClick={handleSave}
            >
              save
            </Button>
            <Button
              size="xs"
              colorScheme="blue"
              variant="outlined"
              onClick={() => setShowPopup(false)}
            >
              cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default LinkEditorButton;
