import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import React, { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdAttachMoney, MdEmail, MdPerson, MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import { STATUS_TYPES, PAYMENT_PURPOSE } from "../../constants";
import { FormInput } from "components/FormInput";
import { useAuth } from "contexts/AuthProvider";
const NewPayout = forwardRef(({ isOpen, onClose }, ref) => {
  const { auth } = useAuth();
  const queryClient = useQueryClient();
  const invalidatePayouts = async () =>
    await queryClient.invalidateQueries({ queryKey: ["/api/payouts/"] });
  const [searchTerm, setSearchTerm] = useState('');
  const [clientSearchFilter, setClientSearchFilter] = useState(null);

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm?.length > 2) {
        setClientSearchFilter(searchTerm);
      }
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const isAffiliatesContributor = auth?.user?.role === 'affiliatesContributor';

  async function postNewPayment(data) {
    return await Client(true).post(`/api/payouts`, JSON.stringify(data));
  }

  async function getClients() {
    return await Client(true).get(
      `/api/trade-accounts?limit=10&query=${clientSearchFilter}&populate=client`
    );
  }

  const { data: accountSearchResults, isLoading } = useQuery({
    queryKey: ["clientSearch", clientSearchFilter],
    queryFn: getClients,
    enabled: !!clientSearchFilter, // only run the query if we have a `clientSearchFilter`
  });

  const newPaymentMutation = useMutation({
    mutationFn: postNewPayment,
    onSuccess: () => {
      invalidatePayouts();
      reset();
      onClose();
      toast.success("New payout request submitted!");
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    data.amount = parseFloat(data.amount);
    data.status = STATUS_TYPES.UNUSED.API_KEY;
    newPaymentMutation.mutate(data);
  };

  const client = watch("client");
  const account = watch("account");
  const name = watch("name");
  const selectedPurpose = watch("purpose");

  React.useEffect(() => {
    // If client or account is selected, do not change spelling of name
    if (client || account) {
      return;
    }
    if (name) {
      setValue(
        "name",
        name.replace(/\b\w/g, (char) => char.toUpperCase())
      );
    }
  }, [account, client, name, setValue]);

  return (
    <Modal
      h={"fit-content"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      finalFocusRef={ref}
    >
      <ModalOverlay />
      <ModalContent isCentered>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalHeader>New Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"fit-content"}>
            <VStack gap={1} spacing={1} letterSpacing={1}>
              <FormControl
                isInvalid={errors.purpose}
                m={0}
              >
                <Select
                  placeholder="Payment Type"
                  isRequired={true}
                  type="text"
                  fontWeight="500"
                  size="lg"
                  textAlign={"center"}
                  {...register("purpose", {
                    required: "This field is required",
                  })}
                >
                  {!isAffiliatesContributor && Object.keys(PAYMENT_PURPOSE).map((key) => (
                    <option key={key} value={PAYMENT_PURPOSE[key]}>
                      {key}
                    </option>
                  ))}
                  {isAffiliatesContributor && (
                    <option value="Affiliate">Affiliate</option>
                  )}
                </Select>
                <FormErrorMessage flexGrow={1} justifyContent={"end"}>
                  {errors.purpose?.message}
                </FormErrorMessage>
              </FormControl>

              {selectedPurpose === PAYMENT_PURPOSE["Researcher"] ? (
                <Popover
                  isOpen={clientSearchFilter !== null}
                  onClose={() => setClientSearchFilter(null)}
                  isLazy
                  matchWidth
                  autoFocus={false}
                >
                  <PopoverTrigger>
                    <FormControl variant="floating" mt="1em">
                      <InputGroup size="lg">
                        <InputLeftElement h="full">
                          <MdSearch />
                        </InputLeftElement>
                        <Input
                          placeholder="Account Login, Email, or Name"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <FormLabel>Find Account</FormLabel>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent w="full" h={"300px"}>
                    <PopoverArrow />
                    <PopoverCloseButton my={2} />
                    <PopoverBody h="full" pt={10} pb={4} spacing={1}>
                      <Skeleton
                        isLoaded={!isLoading}
                        h="full"
                        overflowX="hidden"
                        overflowY="auto"
                      >
                        {
                          accountSearchResults?.data?.docs?.length > 0 ? (
                            accountSearchResults.data.docs.map((account) => (
                              <>
                                <Card alignItems="start" w="full" key={account._id}
                                  onClick={() => {
                                    setValue("name", account.client.name);
                                    setValue("email", account.client.email);
                                    setValue("client", account.client.id);
                                    setValue("account", account.id);
                                    setSearchTerm('');
                                    setClientSearchFilter(null);
                                  }}>
                                  <CardBody p="10px 0" w="full" _hover={{ bg: "var(--chakra-colors-whiteAlpha-100)" }}>
                                    <Heading size="xs" textOverflow="ellipsis">
                                      {account.login}
                                    </Heading>
                                    <Text size="xs" fontSize="xs" textOverflow="ellipsis">
                                      {account.description}
                                    </Text>
                                    <Text size="xs" fontSize="xs" textOverflow="ellipsis">
                                      {account.client.email}
                                    </Text>
                                  </CardBody>
                                </Card>
                                <Divider />
                              </>
                            ))
                          ) : (
                            <Alert status="error">
                              <AlertIcon />
                              No results found
                            </Alert>
                          )}
                      </Skeleton>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : null}
              <FormInput
                id="name"
                placeholder={"John Doe"}
                control={control}
                icon={<MdPerson />}
                register={register("name", {
                  required: "This field is required",
                  minLength: {
                    value: 6,
                    message: "Minimum length should be 6",
                  },
                })}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\b\w/g, (char) =>
                    char.toUpperCase()
                  );
                }}
                disabled={!selectedPurpose || client || account}
                label={"Payee's Legal Name"}
                size="lg"
              />

              <FormInput
                id="email"
                placeholder={"john.doe@example.com"}
                control={control}
                icon={<MdEmail />}
                register={register("email", {
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                disabled={!selectedPurpose || client || account}
                label={"Email Address"}
                size="lg"
                type="email"
              />

              <FormInput
                id="amount"
                placeholder="500.00"
                control={control}
                icon={<MdAttachMoney />}
                disabled={!selectedPurpose}
                label={"Amount"}
                size="lg"
                min={0}
                precision={2}
                step={50}
                type="number"
                register={register("amount", {
                  valueAsNumber: true,
                })}
                allowMouseWheel
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              w="100%"
              type="submit"
              colorScheme="green"
              disabled={!(selectedPurpose && isDirty && isValid)}
            >
              Save Payment
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal >
  );
});

export default NewPayout;
