// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalContent,
  ModalOverlay,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import Pagination, { PAGE_SIZES } from "components/pagination/pagination";
import TemplatesTable from "./components/TemplatesTable";
import ActionButton from "components/ActionButton";
import { MdAdd } from "react-icons/md";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";

export default function TemplateManagement() {
  const [page, setPage] = useState(0);
  const [forceLoad, forceReload] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES["xxxl"]);
  const [textFilter, setTextFilter] = useState("");
  const debouncedSetFilter = debounce(setTextFilter, 500);
  const newTemplateModal = useDisclosure();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    createTemplate.mutate(values);
    newTemplateModal.onClose();
    reset();
  };

  const fetchTemplates = async (page, pageSize, filters) => {
    const data = Client(true).get(
      `/api/templates?limit=${pageSize}&page=${page}${filters}`
    );
    return await data;
  };

  const createTemplateFn = async (values) => {
    const { allowedVariables, ...rest } = values;
    // @TODO let's modify to use array/tags component
    const payload = { ...rest };
    const client = Client(true);
    await client.post(`/api/templates`, payload);
  };
  const deleteTemplateFn = async (id) => {
    const client = Client(true);
    await client.delete(`/api/templates/${id}`);
  };

  const queryClient = useQueryClient();

  const createTemplate = useMutation({
    mutationFn: createTemplateFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
      await queryClient.invalidateQueries({
        queryKey: [
          `/api/trade-accounts/`,
          page,
          pageSize,
          textFilter,
          forceLoad,
        ],
      });
    },
  });

  const deleteTemplate = useMutation({
    mutationFn: deleteTemplateFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
      await queryClient.invalidateQueries({
        queryKey: [
          `/api/trade-accounts/`,
          page,
          pageSize,
          textFilter,
          forceLoad,
        ],
      });
    },
  });

  const { data, loading } = useQuery({
    queryKey: [`/api/trade-accounts/`, page, pageSize, textFilter, forceLoad],
    queryFn: async () => {
      let filters = "";

      if (textFilter) {
        filters += `&query=${textFilter}`;
      }

      return await fetchTemplates(page, pageSize, filters);
    },
    keepPreviousData: true,
    initialData: { data: { docs: [] } },
  });
  useEffect(() => {
    if (data && page > data.data.totalPages) {
      setPage(data.data.totalPages);
    }
  }, [data, page]);

  return (
    <Box>
      <Flex mb={4} wrap="wrap" gap={3}>
        <Flex
          justifyContent={"space-between"}
          spacing={[2, 5]}
          w={"100%"}
          direction={["column", "row"]}
        >
          <FormControl width={"100%"}>
            <Input
              placeholder="Enter Client ID, TradeAccount ID or email"
              onChange={(e) => {
                debouncedSetFilter(e.target.value);
              }}
            />
          </FormControl>
          <ActionButton
            key={"new"}
            colorScheme="green"
            onClick={newTemplateModal.onOpen}
          >
            <MdAdd />
          </ActionButton>
        </Flex>
      </Flex>
      {data ? (
        <TemplatesTable
          tableData={data.data}
          loading={loading}
          onDataLoad={() => {
            forceReload(forceLoad + 1);
          }}
          onDelete={(id) => {
            deleteTemplate.mutate(id);
          }}
        />
      ) : (
        "Loading..."
      )}

      <Modal
        isOpen={newTemplateModal.isOpen}
        onClose={newTemplateModal.onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack gap={2}>
              <FormControl isInvalid={errors.name} variant="floating">
                <InputGroup>
                  <Input
                    {...register("name", {
                      required: "This is required",
                      disabled: true,
                    })}
                  />
                </InputGroup>
                <FormLabel>Template Name</FormLabel>
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.code} variant="floating">
                <InputGroup>
                  <Input
                    {...register("code", {
                      required: "This is required",
                      disabled: true,
                    })}
                  />
                </InputGroup>
                <FormLabel>Template Code</FormLabel>
                <FormErrorMessage>
                  {errors.code && errors.code.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.subject} variant="floating">
                <InputGroup>
                  <Input {...register("subject", {})} />
                </InputGroup>
                <FormLabel>Email Subject</FormLabel>
                <FormErrorMessage>
                  {errors.subject && errors.subject.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.intro} variant="floating">
                <InputGroup>
                  <Textarea {...register("intro", {})} />
                </InputGroup>
                <FormLabel>Email Intro</FormLabel>
                <FormErrorMessage>
                  {errors.intro && errors.intro.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.outro} variant="floating">
                <InputGroup>
                  <Textarea {...register("outro", {})} />
                </InputGroup>
                <FormLabel>Email outro</FormLabel>
                <FormErrorMessage>
                  {errors.outro && errors.outro.message}
                </FormErrorMessage>
              </FormControl>

              <Button type="submit">Submit</Button>
            </VStack>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}
